@import '../../styles/base.scss';

.heading {
  position: absolute;

  &.left {
    left: $lg_margin;
    width: 35%;

    h1 {
      margin: 0;
    }
  }
  &.true {
    right: $lg_margin;
    width: 50%;
  }

  &.center {
    align-self: center;
    text-align: center;
    width: 60%;
  }

  &.right {
    right: $lg_margin;
    width: 35%;
  }

  & h1 {
    @include Abril_Fatface($grey, $heading_size);
  }

  & h4 {
    @include Bellota_Text($grey);
    margin-top: 2em;
    text-align: inherit;
  }
}

.tablet {
  .heading {
    width: 80%;
    text-align: center;
  }
}

.mobile {
  .heading {
    width: 80%;
    left: unset;
    right: unset;

    & h1 {
      @include Abril_Fatface($grey, $heading_size_mobile);
      text-align: center;
    }

    & h4 {
      text-align: center;
    }

    &.left.halfhero {
      top: 20vh;
      text-align: left;
      position: relative;
    }
  }
}
