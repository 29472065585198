@import './styles/base.scss';

body {
  margin: 0 auto;
  padding: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  transition: font 0.3s ease;
  user-select: none;
}

@font-face {
  font-family: 'Abril Fatface', cursive;
  font-weight: 400;
  src: local('Abril Fatface'),
    url(./media/fonts/AbrilFatface-Regular.ttf) format('tff');
}

@font-face {
  font-family: 'Amatic SC', cursive;
  font-weight: 400;
  src: local('Amatic SC bold'),
    url(./media/fonts/AmaticSC-Regular.ttf) format('tff');
}

@font-face {
  font-family: 'Amatic SC Bold', cursive;
  font-weight: 700;
  src: local('Amatic SC bold'),
    url(./media/fonts/AmaticSC-Bold.ttf) format('tff');
}

@font-face {
  font-family: 'Bellota Text', cursive;
  font-weight: 400;
  src: local('Bellota Text'),
    url(./media/fonts/BellotaText-Regular.ttf) format('tff');
}
