@import '../../../../styles/base.scss';
.menu-icon {
  height: 2.2em;
  position: fixed;
  z-index: 8;
  left: 1em;
  top: 1em;
  cursor: pointer;

  &.closed {
    display: none !important;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 400ms;
  opacity: 0;
  pointer-events: none;
  z-index: 9;

  &.open {
    pointer-events: all;
    opacity: 1;
  }
}

.navbar {
  height: 100vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  transform: translateX(-100%);
  transition: transform 400ms;

  &.open {
    transform: translateX(0%);
  }

  .upper-section {
    background-color: $dark-green;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
    height: 40%;

    & .icon {
      height: 8em;
      left: 0;
      position: absolute;
      top: 1.3em;
    }

    .x:after {
      z-index: 100;
      display: inline-block;
      content: '\00d7'; /* This will render the 'X' */
      position: absolute;
      color: $white;
      right: 0.2em;
      top: 0;
      font-size: 3em;
      cursor: pointer;
    }

    & .contact-section {
      text-align: center;

      & p {
        @include Bellota_Text($white);
      }
    }
  }

  .bottom-section {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em 0;
    height: 60%;

    & a {
      @include Abril_Fatface($grey, $lg);
      margin: 0.5em 0;
      text-decoration: none;
    }

    & .bottom-image {
      height: 8em;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 5;
    }
  }
}

.mobile {
  .menu-icon {
    height: 3em;
  }

  .navbar {
    width: 100vw;
    .upper-section {
      & .x:after {
        color: $grey;
        right: 0.2em;
        font-size: 4em;
      }

      & .contact-section {
        bottom: 0;
      }
    }
  }
}
