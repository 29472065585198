@import '../../../../styles/base.scss';

.split {
  display: flex;
  .left-image {
    height: 100vh;
    width: 50%;
    flex: 1;
    z-index: -1;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.split_section {
  .right-image {
    height: 100vh;
    width: 50%;
    z-index: -1;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.mobile {
  & .heading.center {
    margin: 0;
  }
  .split {
    flex-direction: column-reverse;

    .left-image {
      width: 100vw;
      filter: brightness(0.6);
    }
    .right-image {
      width: 100vw;
      filter: brightness(0.9);
    }
  }
}
