@import '../../../../styles/base.scss';

.contact {
  @include Bellota_Text($grey);
  flex-flow: row wrap;
  text-align: center;
  justify-content: space-between;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 4em;

  .contact-text-section {
    flex: 1 100%;

    .contact-text {
      margin: 0 auto;
      margin-bottom: 4em;
      font-size: $lg;
      width: 90%;
    }
  }

  a {
    text-decoration: none;
    color: $white;
    padding: 0.3em 0em;

    @include Bellota_Text($grey);
    :visited {
      color: $grey;
    }
  }

  & .contact-section {
    font-size: $lg;
    text-align: left;
  }

  & .icon-section {
    padding: 0 1em;

    & img {
      height: 5em;
    }
  }
}

.mobile {
  .contact {
    height: 100%;
    margin: 4em 0;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    & .contact-section {
      text-align: center;
    }

    & .icon-section {
      padding: 1em;
    }
  }
}
