@import '../../../../styles/base.scss';

button:focus {
  outline: 0;
}
select:focus {
  outline: none;
}

.delia-section {
  display: flex;
  width: 90%;
  margin: auto;

  .delia-lead {
    width: 70%;
    margin-bottom: 4em;

    @include Bellota_Text($grey);


  }
  
  .delia-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

 
}
.searchfield-section {
  display: flex;
  margin: 0 auto;
  width: 90%;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2em;

 

  .filters {
    .button {
      display: inline-block;
      margin: 0 1em 1em;

      &.delia-button {
        position: absolute;
        left: 70%;
      }
    }
  }

  & .products-button a {
    float: left;
    margin: 1em;

    &:nth-child(3n + 1) {
      clear: left;
    }
  }

  & form {
    margin: 1em;
  }

  .search {
    font-size: 0.9em;
    font-family: 'Abril Fatface';
    color: $grey;
    border: 1px solid $grey;
    margin: 0 0 1em 0;
    border-radius: 1.8em;
    box-sizing: border-box;
    background: $white;
    padding: 1em 3em;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $off-white;
    align-self: flex-end;
    outline: none;

    &.small {
      font-size: 0.8em;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey;
    }
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em;
  justify-content: space-around;
  padding: 0 3em;
  padding-top: 5em;
  padding-bottom: 1em;
  background-color: $off-white;

  .no-products {
    display: grid;
    grid-template-columns: 100vw;
    margin: 0 auto;
    width: 80%;

    h2 {
      @include Abril_Fatface($grey, $xl);
    }
  }

  & article {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 21%;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 0.5em;
    background-color: $white;
    cursor: pointer;

    &:hover {
      box-shadow: 2px 2px 4px 5px #ccc;
    }

    & h3 {
      @include Abril_Fatface($grey, $med);
      margin: 0.3em;
    }
    & h5 {
      @include Bellota_Text($grey);
      margin: 0;
    }
    & img {
      width: 100%;
      height: 18em;
      align-self: center;
      object-fit: contain;
    }
  }
}

.product-wrapper {
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms;

  .current_product {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5em;
    overflow: scroll;
    height: 30em;

    & h2 {
      @include Abril_Fatface($white, $xl);
    }

    & h4,
    p {
      @include Bellota_Text($white);
    }

    & .left-section {
      flex: 1;
      display: flex;
      width: 25em;
      object-fit: contain;
      background-color: $white;
      justify-content: center;
      align-items: center;
      padding: 2em;
      box-sizing: border-box;

      & img {
        width: 100%;
        height: 22em;
        align-self: center;
        object-fit: contain;
      }
    }

    & .right-section {
      display: flex;
      flex-direction: column-reverse;
      flex: 1;
      width: 25em;
      padding: 2em;
      box-sizing: border-box;
      overflow: auto;

      & img {
        height: 3em;
        object-fit: contain;
        object-position: 50% 50%; /* default value: image is centered*/
        object-position: right bottom;
      }
    }

    & .x:after {
      z-index: 100;
      display: inline-block;
      content: '\00d7'; /* This will render the 'X' */
      position: absolute;
      color: $white;
      right: 0.4em;
      top: 0;
      font-size: 2.1em;
      cursor: pointer;
    }
  }
}

.pagination-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $off-white;

  & .slides_button {
    font-family: 'Bellota Text';
    color: $lighter-red;
    position: relative;
    margin: 0;
    padding: 1em 1.5em;
    border-radius: 50%;
    box-sizing: border-box;
    text-decoration: none;
    background: $white;
    margin: 4em 2em;
    border: 1px solid $lighter-red;
    cursor: pointer;

    &.active_page {
      background: $lighter-red;
      color: $white;
    }
  }
}

.tablet {
  .products {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding: 3em 1em;

    .no-products {
      & h2 {
        width: 80%;
        text-align: center;
      }
    }
  }

  .current_product {
    flex-direction: column;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    height: 90%;

    & .x:after {
      z-index: 100;
      display: inline-block;
      position: absolute;
      content: '\00d7'; /* This will render the 'X' */
      color: $white;
      right: 1em;
      top: 90%;
      font-size: 2.5em;
    }
  }
  .searchfield-section {
    display: flex;
    flex-direction: column;

    .filters {
      .button {
        &.delia-button {
          position: unset;
        }
      }
    }
  }
}
.mobile {

  .delia-section {
    display: flex;
    flex-direction: column;

    .delia-lead {
      margin: auto;
      width: 100%;
    }

    .delia-image {
      margin: auto;

      img {
        width: 12em;
        margin-top: 1em;
      }
    }
  }
  .searchfield-section {
    display: flex;
    flex-direction: column;

    .filters {
      width: 100%;
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .categories,
      .countries {
        width: 90%;
        padding: 1em 3em;
        font-family: 'Abril Fatface';
        color: $lighter-red;
        border: 0.1em solid $lighter-red;
        border-radius: 2.2em;
        box-sizing: border-box;
        text-decoration: none;
        background: $white;
        margin-top: 1em;
        background-image: url('../../../../media/images/Polygon.svg');
        background-repeat: no-repeat;
        object-fit: contain;
        background-position: 90% 50%;
        background-size: 1.5em;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
        text-indent: 1px;
        text-overflow: '';
        select::-ms-expand {
          display: none;
        }
        select {
          -webkit-appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
        }
      }
    }

    .search-wrapper {
      margin: 0 auto;
      .search {
        font-size: 1em;
        padding: 1em 5em;
        box-sizing: border-box;
        margin-bottom: 0;
        margin-top: 3em;
      }
      ::placeholder {
        text-align: left;
      }
    }
  }
  .products {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding: 3em 1em;

    .no-products {
      & h2 {
        width: 80%;
        text-align: center;
      }
    }
  }

  .current_product {
    flex-direction: column;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 90%;

    & .x:after {
      z-index: 100;
      display: inline-block;
      position: absolute;
      content: '\00d7'; /* This will render the 'X' */
      color: $white;
      right: 1em;
      top: 90%;
      font-size: 2.5em;
    }
  }
  .pagination-container {
    overflow-x: auto;
    width: 100%;
    justify-content: unset;

    .slides_button {
      margin: 1em 1em 5em 1em;
    }
  }
}

.tablet {
  .product-header {
    .heading {
      width: 80%;
    }
  }
  .searchfield-section {
    align-items: center;
  }
}
