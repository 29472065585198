@import '../../styles/base.scss';

.button {
  font-family: 'Abril Fatface';
  color: $white;
  position: relative;
  padding: 1.4em 2.2em;
  border-radius: 2.2em;
  box-sizing: border-box;
  text-decoration: none;
  background: $lighter-red;
  margin: 0;
  margin-right: 1em;
  outline: none;
  cursor: pointer;
  border: 0.1em solid transparent;

  &.small {
    font-size: 0.8em;
  }

  &.big {
    padding: 0.8em 2em;
  }

  &:hover {
    color: $lighter-red;
    background: transparent;
    border: 0.1em solid $lighter-red;
  }

  &.outlined {
    background: $white;
    border: 0.1em solid $lighter-red;
    color: $lighter-red;

    &:hover {
      background: $lighter-red;
      border: 0.1em solid transparent;
      color: $white;
    }

    &.active {
      background: $lighter-red;
      border: 0.1em solid transparent;
      color: $white;
    }
  }

  &.outlined-2 {
    background: transparent;
    border: 0.1em solid $lighter-green;
    color: $lighter-green;

    &:hover {
      background: $dark-green;
      border: 0.1em solid transparent;
    }
  }

  &.dark-red {
    background-color: transparent;
    color: $white;
    border: 0.1em solid $white;

    &:hover {
      background-color: $dark-red;
      border: 0.1em solid $dark-red;
      color: $white;
    }
  }
}

.mobile {
  .button {
    left: unset;
  }
}
