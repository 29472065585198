@import '../../../../styles/base.scss';

.footer {
  height: 55vh;
  display: flex;
  justify-content: space-around;
  background-color: $grey;

  & h4 {
    @include menu_text($white, $med);
  }

  .contact-info {
    height: 12em;
    align-self: center;
    box-sizing: border-box;

    & p {
      @include Bellota_Text($white);

      align-self: center;
    }

    a {
      text-decoration: none;

      @include Bellota_Text($white);
      :visited {
        color: $white;
      }
    }
  }

  .logo {
    align-self: center;

    & img {
      height: 3.5em;
      align-self: center;
      cursor: pointer;
    }
  }

  .social {
    align-self: center;

    & img {
      height: 2em;
      align-self: center;
      padding: 0 .8em;
    }
  }

  .site-links {
    display: flex;
    flex-direction: column;
    height: 12em;
    align-self: center;
    box-sizing: border-box;

    & a {
      text-decoration: none;
      color: $white;
      padding: 0.3em 0em;

      @include Bellota_Text($white);
      :visited {
        color: $white;
      }
    }
  }
}

.mobile {
  .footer {
    flex-direction: column;
    padding: 1em;
    height: auto;
    font-size: $lg;

    & .site-links,
    & .contact-info {
      order: 2;
      align-self: flex-start;
      justify-content: center;
    }

    & .logo {
      order: 3;
      display: flex;
      justify-content: center;
      padding-top: 2em;
    }

    & .social {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
