$dark-red: #7a2d1a;
$lighter-red: #c54c2e;
$dark-green: #606961;
$lighter-green: #cbd9cd;
$grey: #484340;
$white: #ffff;
$off-white: #f6f6f6;
$xl_margin: (168 / 19.2) * 1em;
$lg_margin: (89 / 19.2) * 1em;
$med_margin: (64 / 19.2) * 1em;

/* font-size */

$sm: (14 / 19.2) * 1em;
$med: (21 / 19.2) * 1em;
$lg: (26 / 19.2) * 1em;
$xl: (36 / 19.2) * 1em;
$xxl: (52 / 19.2) * 1em;

$heading_size: 4em; //110px
$heading_size_mobile: (50 / 19.2) * 1em; //48px

@mixin Abril_Fatface($color, $size) {
  font-family: 'Abril Fatface';
  color: $color;
  font-size: $size;
}

@mixin Bellota_Text($color) {
  font-family: 'Bellota Text';
  color: $color;
}

@mixin menu_text($color, $size) {
  font-family: 'Bellota Text';
  color: $color;
  font-size: $size;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

@mixin Amatic_SC_Bold($color, $size) {
  font-family: 'Amatic SC Bold';
  font-weight: 700;
  color: $color;
  font-size: $size;
}

:export {
  dark_red: $dark-red;
  lighter_red: $lighter-red;
  dark_green: $dark-green;
  lighter_green: $lighter-green;
  grey: $grey;
  white: $white;
  off_white: $off-white;
  xl_margin: $xl_margin;
  lg_margin: $lg_margin;
  med_margin: $med_margin;
}

.cookie-banner {
  position: fixed;
  z-index: 7;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $dark-green;
  color: white;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  p {
    @include Bellota_Text($white);
    margin: 0;
  }

  .button {
    font-size: 0.8em;
  }
}

.mobile .cookie-banner {
  flex-direction: column;

  .button {
    font-size: 0.8em;
    margin-top: 2em;
  }
}
