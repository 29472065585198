@import '../../../../styles/base.scss';

.half_hero {
  height: 24em;
  position: relative;

  & .bg_image {
    height: 18em;
    width: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
  }

  .heading {
    bottom: 0;
    position: absolute;
    width: 40%;
  }

  & .half-hero-logo {
    z-index: 6;
    align-self: center;
    position: absolute;
    top: 1em;
    cursor: pointer;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
      width: 10em;
      object-fit: contain;

    }
  }

  & .half-hero-social {
    z-index: 2;
    align-self: flex-end;
    position: absolute;
    top: 1em;
    right: 1em;

    & img {
      padding: 0 1em;
      width: 2em;
      object-fit: contain;
    }
  }
}

.tablet {
  .half_hero .heading {
    width: 90%;
  }
}
.mobile {
  .half_hero {
    height: 60vh;

    .heading {
      width: 90%;
    }

    & .bg_image {
      height: 50vh;
      width: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0;
    }
  }
}
