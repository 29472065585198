@import '../../../../styles/base.scss';

.block.gallery {
  position: relative;

  --anim: 1s;

  .slides-button {
    cursor: pointer;
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 2;
    background: none;
    border: none;
    font-size: 0.5em;
    img {
      height: 4em;
    }
  }

  .gallery-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity var(--anim);
    pointer-events: none;

    &.current {
      opacity: 1;
      pointer-events: all;

      & > img {
        transform: translateX(0%);
        opacity: 1;
      }

      .gallery-content {
        transform: translateX(0);
        opacity: 1;

        .gallery-content-wrapper {
          .gallery-heading {
            h1 {
              transform: translateX(0);
              text-align: left;
            }
            h4 {
              transform: translateX(0);
              text-align: left;
            }
          }
          .lower-content {
            .button {
              transform: translateX(0);
            }
          }
        }
      }
    }

    display: flex;

    & > img {
      pointer-events: none;
      object-fit: cover;
      flex-grow: 1;
      width: 0;
      filter: brightness(0.9);
      transform: translateX(-20%);
      opacity: 0;
      transition: transform calc(var(--anim) * 1),
        opacity calc(var(--anim) * 0.8);
    }

    .gallery-content {
      flex-shrink: 0;
      width: 30em;
      padding: 4em 0;
      display: flex;
      align-items: center;
      z-index: 1;
      opacity: 0;
      transform: translateX(10%);
      transition: transform calc(var(--anim) * 1),
        opacity calc(var(--anim) * 0.8);

      .gallery-content-wrapper {
        width: 100%;
        margin-left: -6em;

        .gallery-heading {
          width: 70%;

          h1 {
            @include Abril_Fatface($grey, $heading_size);
            margin: 0;
            transform: translateX(10%);
            transition: transform calc(var(--anim) * 1);
          }

          h4 {
            @include Bellota_Text($grey);
            margin: 0;
            margin-top: 2em;
            transform: translateX(20%);
            transition: transform calc(var(--anim) * 1);
          }
        }

        .lower-content {
          display: flex;
          justify-content: space-between;

          .button {
            margin: 0;
            display: inline-block;
            margin-top: 3em;

            transform: translateX(30%);
            transition: transform calc(var(--anim) * 1);
          }

          .gallery-icon {
            margin-top: 0.5em;
            width: 50%;
            max-height: 10em;
            object-fit: contain;
            object-position: right bottom;
          }
        }
      }
    }
  }

  .gallery-nav {
    position: absolute;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    div {
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;
      background-color: white;
      opacity: 0.4;
      border-radius: 1em;
      transition: opacity 500;

      &:not(:last-child) {
        margin-right: 0.5em;
      }

      &.current {
        opacity: 1;
      }
    }
  }
}

.tablet {
  .block.gallery {
    .gallery-slide {
      & > img {
        filter: brightness(0.6);
      }
    }
  }
}

.mobile {
  .block.gallery {
    min-height: 150vh;
    .gallery-slide {
      flex-direction: column-reverse;
      align-items: center;



      & > img {
        transform: translateX(0);
        flex-shrink: 1;
        width: 100%;


      }
      .gallery-content {
        width: 90%;
        max-height: 70em;

        .gallery-content-wrapper {
          margin-left: 0;

          .gallery-heading {
            width: 100%;
          }

          .lower-content {
            justify-content: center;
            .button {
            }
            .gallery-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}
