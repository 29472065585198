@import '../../styles/base.scss';

.block {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .cookie-banner {
    height: 30vh;
    width: 100vw;
    z-index: 100;
    position: absolute;
  }

  &.single_product {
    justify-content: flex-start;
    height: auto;
  }
}

.mobile {
  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
